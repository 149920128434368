
  import { Component, Vue } from 'vue-property-decorator';
  import { RejectionReasons } from '@/interfaces';

  @Component
  export default class RejectionBtn extends Vue {
    get reasons(): Record<string, string> {
      return RejectionReasons;
    }
  }
