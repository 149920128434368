
  import { Prop, Component, Vue } from 'vue-property-decorator';
  import { OrderModel } from '@/models';
  import ProductListMods from './ProductListMods.vue';

  @Component({ components: { ProductListMods } })
  export default class ProductList extends Vue {
    @Prop() readonly order!: OrderModel;
    selectedItemDescription = '';
    itemImages: string[] = []
    showBottomSheet = false;

    showItemDetails(item: any) {
      this.selectedItemDescription = item.technicalDescription;
      this.itemImages = [(item.technicalPhotos)]
      this.showBottomSheet = true;
    }

  }
