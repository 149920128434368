import { TextValuePair } from "@/interfaces";
import { ConceptModel } from "@/models";

export function getLocationsFromConceptList(concepts: ConceptModel[]): TextValuePair[] {
  return concepts?.map(item => ({ value: item.locationId, text: item.location?.name || '' }))
    .filter((item, ndx, array) => ndx === array.findIndex(_item => _item.value === item.value))
    .sort((a, b) => a.text < b.text ? -1 : 1) || [];
}

export function getConceptsForLocation(concepts: ConceptModel[], locationId: number): TextValuePair[] {
  return concepts?.filter(item => item.locationId === locationId)
    .map(item => ({ value: item.id, text: item.name }))
    .sort() || [];
}
