
  import { Prop, Vue, Component } from 'vue-property-decorator';
  import { OrderModel } from '@/models';

  @Component
  export default class CallMarketplaceToCancelOrder extends Vue {
    @Prop() readonly order!: OrderModel;

    get supportPhoneNo(): string {
      return this.$t(`orders.phones.${ this.order.source }`) as string;
    }
  }
