
import { Vue, Component, Prop } from 'vue-property-decorator';
import { OrderModel } from '@/models';
import { namespace } from 'vuex-class';

const ordersStore = namespace('orders');
@Component
export default class CourierStatusChanged extends Vue {
  @Prop() order!: OrderModel;

  @ordersStore.Mutation
  readonly removeOrderRequiredSync!: (orderId: number) => void;

  @ordersStore.Action
  readonly getOrderDetails!: (orderId: number) => Promise<OrderModel | null>

  loading = false;

  async refreshData() {
    try {
      this.loading = true;
      const updatedOrder = await this.getOrderDetails(this.order.id);
      this.removeOrderRequiredSync(this.order.id);

      if (updatedOrder) {
        this.order.updateProperties(updatedOrder);
      }
    } finally {
      this.loading = false;
    }
  }
}
