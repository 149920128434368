
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { hasAccess } from '@/helpers/user';
import { Role } from '@/interfaces';

const appStore = namespace('app');

@Component
export default class extends Vue {
  @Prop() readonly to?: string;
  @Prop({ default: false }) readonly inline?: boolean;

  @appStore.Getter
  readonly userRole!: Role;

  get componentName(): string {
    return this.to || this.$slots.default?.[0].componentOptions?.tag || '';
  }

  get hasAccess(): boolean {
    return hasAccess(this.userRole, this.componentName);
  }
}
