
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  
  import { getLocationsFromConceptList, getConceptsForLocation } from '@/helpers/concepts'
  import { TextValuePair, OrdersFilters } from '@/interfaces';
  import { ConceptModel } from '@/models';
  import CountriesFilter from './Countries.vue';

  const conceptsStore = namespace('concepts');

  @Component({ components: { CountriesFilter } })
  export default class OrderFilters extends Vue {
    @Prop({ default: () => ({}) }) readonly initFilters?: OrdersFilters;
    @Prop({ default: false }) readonly showOnlyOrderId?: boolean

    filters: OrdersFilters = { };

    @conceptsStore.State('conceptsForUser')
    readonly userConcepts!: ConceptModel[];

    get statuses(): TextValuePair[] {
      const statusNames = this.$t('orders.statuses');
      return Object.keys(statusNames).map(key => ({ value: key, text: statusNames[key] }));
    }

    get sources(): TextValuePair[] {
      const sourceNames = this.$t('orders.sources');
      return Object.keys(sourceNames).map(key => ({ value: key, text: sourceNames[key] }));
    }

    get locations(): TextValuePair[] {
      return getLocationsFromConceptList(this.userConcepts);
    }

    get concepts(): TextValuePair[] {
      return getConceptsForLocation(this.userConcepts, this.filters.locationId as number);
    }

    @Watch('initFilters')
    onInitFiltersChange(): void {
      this.filters = {
        status: this.initFilters?.status || [],
        locationId: this.initFilters?.locationId || null,
        conceptsIds: this.initFilters?.conceptsIds || [],
        countries: this.initFilters?.countries || [],
        orderId: this.initFilters?.orderId || '',
        source: this.initFilters?.source || [],
      }
    }

    update(): void {
      this.$emit('updated', this.filters);
    }

    updateCountries(countries: string[]): void {
      this.filters.countries = countries;
      this.update();
    }
  }
