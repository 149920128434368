
 import { Prop, Component, Vue } from 'vue-property-decorator';
 import { OrderModel } from '@/models';
 import { LocationDeliveryType } from '@/interfaces';

 @Component
 export default class Values extends Vue {
     @Prop() readonly order!: OrderModel;

     readonly responsibleEntitiesMarketplace = [LocationDeliveryType.delivery_by_stuart, LocationDeliveryType.delivery_by_stava, LocationDeliveryType.delivery_by_deligoo, LocationDeliveryType.delivery_by_provider];

     get isHandledByMarketplacePartner() {
         return this.responsibleEntitiesMarketplace.find((entity) => entity === this.order.deliveryBy);
     }

     get displayDeliveryCost() {
      return this.order?.concept?.restaurant?.external === true || 
           (this.order?.concept?.restaurant?.external === false && !this.isHandledByMarketplacePartner);
      }
 }
