
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { OrdersFilters, OrderStatus, Order as IOrder, OrderSource } from '@/interfaces';
  import { ChangeStatusPayload, GetOrdersPayload } from '@/store/interfaces';

  import Order from '@/components/Order.vue';
  import OrderDetailsModal from '@/components/modals/OrderDetails/index.vue';
  import OrderFilters from '@/components/filters/Orders.vue';
  import CheckAccess from '@/components/CheckAccess.vue';
  import { OrderModel } from '@/models';
  
  const ordersStore = namespace('orders');
  const appStore = namespace('app');

  @Component({ components: { Order, OrderDetailsModal, OrderFilters, CheckAccess } })
  export default class extends Vue {
    selectedOrder: IOrder | null = null;
    filters: OrdersFilters = {};
    canShowLoadMoreBtnForAdmin = true

    @ordersStore.State
    readonly orders!: IOrder[];

    @ordersStore.State
    readonly ordersRequiredSync!: string[]


    @appStore.Getter
    readonly isRebel!: boolean;

    @ordersStore.State
    readonly numberOfOrders!: number;

    get conceptId(): number | null {
      return +this.$route.query.conceptId || null;
    }

    get locationId(): number | null {
      return +this.$route.query.locationId || null;
    }

    get status(): OrderStatus | null {
      return (this.$route.query.status as OrderStatus) || null;
    }

    get source(): OrderSource | null {
      return (this.$route.query.source as OrderSource) || null;
    }

    get orderId(): number | null {
      return +this.$route.query.orderId || null;
    }

    get canShowLoadMoreBtn(): boolean {
      return (this.isRebel && this.canShowLoadMoreBtnForAdmin) || (this.numberOfOrders > this.orders.length);
    }

    @ordersStore.Action('getOrders')
    refetchOrders!: (data: GetOrdersPayload) => Promise<void>;

    @ordersStore.Action
    changeStatus!: (data: ChangeStatusPayload) => Promise<void>;

    @ordersStore.Mutation
    toggleRealTime!: (flag: boolean) => Promise<void>;

    @ordersStore.Action
    orderCourier!: (orderId: number) => Promise<void>;

    @ordersStore.Action
    getOrderDetails!: (orderid: number) => Promise<OrderModel>;

    @Watch('status')
    onStatusChanged(status: OrderStatus): void {
      this.filters.status = !status ? [] : [status];
      this.getOrders(this.filters);
    }

    @Watch('source')
    onSourceChanged(source: OrderSource): void {
      this.filters.source = !source ? [] : [source];
      this.getOrders(this.filters);
    }

    @Watch('orderId')
    async onOrderIdChanged(orderId: number): Promise<void> {
      orderId && await this.showOrderDetails(orderId);
    }

    mounted(): void {
      this.getOrders({
        status: this.status ? [this.status] : [],
        conceptsIds: this.conceptId ? [this.conceptId] : [],
        locationId: this.locationId,
        source: this.source ? [this.source] : [],
      });
    }

    async getOrders(filters: OrdersFilters = {}, fetchMore = false): Promise<void> {
      this.filters = filters;
      await this.refetchOrders({ filters, fetchMore });

      this.orderId && await this.showOrderDetails(this.orderId);
    }

    async showOrderDetails(orderId: number): Promise<void> {
      let order = this.orders.find(order => order.id === orderId);
      if (order && !order?.items) {
        order = await this.getOrderDetails(order.id);
      }
      
      this.selectedOrder = order || null;
    }

    dateChanged(ndx: number): boolean {
      return Boolean(ndx <= 0 || (this.orders[ndx].createdAt.substr(0, 10) !== this.orders[ndx - 1].createdAt.substr(0, 10)));
    }

    async getNextPage(): Promise<void> {
      const ordersCount = this.orders.length;
      await this.getOrders(this.filters, true);
      this.canShowLoadMoreBtnForAdmin = ordersCount !== this.orders.length;
    }
  }
