
  import { Prop, Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { OrderModel } from '@/models';
  import { OrderDeliveryType, OrderStatus, RejectionReasons } from '@/interfaces';
  import CourierError from './CourierError.vue';
  import CanceledWithSelfDeliveryAlert from './CanceledWithSelfDeliveryAlert.vue';
  import ProductList from './ProductList.vue';
  import Values from './Values.vue';
  import OrderDetails from './Details.vue';
  import Customer from './Customer.vue';
  import Invoice from './Invoice.vue';
  import RejectionBtn from './RejectionBtn.vue';
  import AcceptBtn from './AcceptBtn.vue';
  import CallMarketplaceToCancelOrder from './CallMarketplaceToCancelOrder.vue';
  import StatusHistory from './StatusHistory.vue';
  import CanceledByMarketplaceOrExpired from './CanceledByMarketplaceOrExpired.vue';
  import CheckAccess from '@/components/CheckAccess.vue';
  import CourierStatusChanged from '../../banners/CourierStatusUpdated.vue';
  import beep from '@/helpers/beep';

  const conceptsStore = namespace('concepts');

  @Component({
    components: { CourierError, CourierStatusChanged, CanceledWithSelfDeliveryAlert, ProductList, Values, OrderDetails, Customer, Invoice, RejectionBtn, CallMarketplaceToCancelOrder, StatusHistory, CanceledByMarketplaceOrExpired, CheckAccess, AcceptBtn }
  })
  export default class OrderModal extends Vue {
    @Prop({ required: true }) readonly order!: OrderModel;
    @Prop({ required: true }) readonly ordersRequiredSync!: number[];

    visible = true;
    lastStatusChange: OrderStatus | null = null;
    beepHandler: HTMLAudioElement | null = null;

    @conceptsStore.State
    readonly conceptsForUser!: { id: number; name: string }[];

    get conceptName(): string | undefined {
      const concept = this.conceptsForUser.find(item => item.id === this.order.conceptId);
      return concept?.name;
    }

    mounted(): void {
      if (this.order.status === OrderStatus.new) {
        this.beepHandler = beep(false, 'wait_for_acceptance');
      }

      window.addEventListener('popstate', this.closeModal);
    }

    changeStatus(status: OrderStatus, reason?: RejectionReasons, time?: number): void {
      if (this.lastStatusChange === status) {
        return;
      }

      this.$emit('statusChanged', { orderId: this.order.id, status, reason, time });
      this.lastStatusChange = status;

      if (
        (this.order.deliveryType === OrderDeliveryType.delivery_by_us && status === OrderStatus.rejected) ||
        (!this.order.canBeAutoCanceled && status === OrderStatus.rejected)
      ) {
        this.stopBeeper();
        return;
      }

      this.closeModal();
    }

    stopBeeper(): void {
      this.beepHandler?.pause();
    }

    closeModal(): void {
      this.stopBeeper();
      this.$emit('close');
    }

    print(): void {
      window.print();
    }

    orderCourier(): void {
      confirm(this.$t('orders.orderCourierConfirm') as string) && this.$emit('orderCourier', { orderId: this.order.id });
    }
  }
