
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { OrderModel } from '@/models'

  @Component
  export default class Customer extends Vue {
    @Prop() readonly order!: OrderModel

    get hasIncompleteAddress(): boolean {
      return this.order?.delivery?.address?.indexOf('undefined') !== -1;
    }
  }
