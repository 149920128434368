
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { OrderStatus } from '@/interfaces';
import { ConceptModel, OrderModel } from '@/models';
import CheckAccess from '@/components/CheckAccess.vue';

const appStore = namespace('app');
const conceptsStore = namespace('concepts');

@Component({ components: { CheckAccess } })
export default class Order extends Vue {
  @Prop({ required: true }) readonly order!: OrderModel;

  timer: any | undefined = undefined;
  pickupTime: string | null = null;

  @conceptsStore.State('conceptsForUser')
  readonly concepts!: ConceptModel[];

  @appStore.Getter
  readonly isRebel!: boolean;

  get conceptName(): string | undefined {
    const concept = this.concepts.find((item) => item.id === this.order.conceptId);
    if (!concept) {
      return '';
    }

    return !this.isRebel ? concept.name : concept.location?.name + ' - ' + concept.name;
  }

  @Watch('order', { deep: true })
  onOrderChanged(): void {
    if (![OrderStatus.new, OrderStatus.processing].includes(this.order.status) && this.timer) {
      clearInterval(this.timer);
      this.pickupTime = null;
    }
  }

  mounted(): void {
    this.runTimer();
  }

  beforeDestroy(): void {
    this.timer && clearInterval(this.timer);
  }

  runTimer(): void {
    if (
      ![OrderStatus.new, OrderStatus.processing].includes(this.order.status) ||
      !this.order.pickupTime
    )
      return;

    this.timer = setInterval(() => {
      const diff =
        Math.round(
          ((new Date(this.order.pickupTime).getTime() - new Date().getTime()) / 1000 / 60) * 100
        ) / 100;
      if (diff <= 0) {
        clearInterval(this.timer);
        this.pickupTime = null;
        return;
      }

      const minutes = Math.floor(diff);
      const sec = Math.round(((diff - minutes) * 60 * 100) / 100);

      this.pickupTime = (minutes + '').padStart(2, '0') + ':' + (sec + '').padStart(2, '0');
    }, 1000);
  }

  navigateToLocation(locationId: number): void{
      this.$router.push({ path: `location/${locationId}`});
  }
}
