
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AcceptBtn extends Vue {
  @Prop({ default: false }) simple!: boolean;

  get times(): number[] {
    return [0, 5, 10, 15];
  }

  get defaultPrepTime(): number {
    return +(process.env.VUE_APP_DEFAULT_PREP_TIME as string);
  }
}
