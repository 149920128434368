
  import { Prop, Vue, Component } from 'vue-property-decorator';
  import { AllRejectionReasons } from '@/interfaces';

  @Component
  export default class CanceledByMarketplaceOrExpired extends Vue {
    @Prop() readonly rejectionReason!: AllRejectionReasons;

    get isExpired(): boolean {
      return this.rejectionReason === AllRejectionReasons.expired;
    }
  }
